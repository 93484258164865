.Timeline {
  position: relative;
  margin: 0 auto;
}

.Timeline-header {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  color: var(--accent-cool-darker);
  padding-left: 70px;
}

.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid var(--tertiary-bg);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent var(--tertiary-bg);
}

.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid var(--tertiary-bg);
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--tertiary-bg) transparent transparent;
}

.Timeline-content {
  padding: 20px 30px;
  background-color: var(--tertiary-bg);
  position: relative;
  border-radius: 6px;
}

.Timeline::after {
  left: 31px;
  content: "";
  position: absolute;
  width: 6px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  top: 0;
  bottom: 0;
  margin-left: -3px;
  margin-top: 24px;
  height: 0%;
  animation-delay: 2s;
  animation: line 2s 1 linear;
  animation-fill-mode: forwards;
}

.Timeline-container {
  padding: 10px 40px;
  padding-left: 70px;
  padding-right: 25px;
  position: relative;
  background-color: inherit;
  padding-right: 5px;
}

.Timeline-container::before {
  left: 60px;
  border: medium solid var(--tertiary-bg);
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--tertiary-bg) transparent transparent;
}

.right {
  left: 0%;
}

@media screen and (max-width: 480px) {
  .Timeline-container {
    padding: 10px 20px;
    padding-left: 70px;
    padding-right: 5px;
    position: relative;
    background-color: inherit;
  }

  .Timeline-content {
    padding: 20px 20px;
  }
}

@keyframes line {
  0% {
    height: 0%;
  }
  25% {
    height: 25%;
  }
  50% {
    height: 50%;
  }
  75% {
    height: 75%;
  }
  100% {
    height: 95%;
  }
}
