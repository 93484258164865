@import url("https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Monda:wght@400;700&display=swap');

body {
  margin: 0;
  font-size: 12px;
  font-family: "Monda", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color : #e7fefe;
  background-color: var(--primary-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2{
  padding: 0px;
  margin: 0px;
}


:root {
  --primary-bg:  #121212;
  --secondary-bg: #202020;
  --tertiary-bg: #282828;
  --accent-cool: #86fcf8;
  --accent-cool-darker: #00eedc;
  --accent-warm: #B3922F;
  --accent-warmer: #AB5034;
  --font-color: #e7fefe;
  --margin-small: 4px;
  --margin-medium: 8px;
  --margin-large: 16px;
  --margin-larger: 24px;

  --compact-section-margin : 16px;
}

a:link, a:visited {
  color: var(--accent-cool-darker) !important;
  text-decoration: none;
}