.Projects{
  margin : 10px;
}

.Projects-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

@media screen and (max-width: 480px) {
  .Projects-list {
    display: grid;
    grid-template-columns:1fr;
    grid-column-gap: 5px;
    grid-row-gap: var(--compact-section-margin);
  }  

  .Projects{
    margin : 5px var(--compact-section-margin);
  }

  .Projects-entry{
    justify-content: center;
    display: flex;
    
  }

  .Projects-entry {
    padding-bottom: 0;
  }
  
}

