.AboutMe {
  max-width: 900px;
  padding: 10px;
  min-height: 300px;
}
.AboutMeContent {
  display: flex;
  flex-direction: row;

}

.AboutMeTextCont {
  color: var(--accent-cool-darker);
  width: 50%;
  margin-right: 42px;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  white-space: pre-line;
}

.AboutMeImgCont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

}

.AboutMeImg {
  border-radius: 50%;
  width: 80%;
  border-color: (--primary-bg);
  clip-path: circle(49%);

}

@media screen and (max-width: 720px) {
  .AboutMe {
    padding: 5px var(--compact-section-margin);
  }
  .AboutMeContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .AboutMeTextCont {
    color: var(--accent-cool-darker);
    width: 100%;
    margin-right: 0;
  }

  .AboutMeImgCont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .AboutMeImg {
    border-radius: 50%;
    width: 100%;
  }
}
