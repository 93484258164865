@import url(https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Monda:wght@400;700&display=swap);
body {
  margin: 0;
  font-size: 12px;
  font-family: "Monda", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color : #e7fefe;
  background-color: #121212;
  background-color: var(--primary-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2{
  padding: 0px;
  margin: 0px;
}


:root {
  --primary-bg:  #121212;
  --secondary-bg: #202020;
  --tertiary-bg: #282828;
  --accent-cool: #86fcf8;
  --accent-cool-darker: #00eedc;
  --accent-warm: #B3922F;
  --accent-warmer: #AB5034;
  --font-color: #e7fefe;
  --margin-small: 4px;
  --margin-medium: 8px;
  --margin-large: 16px;
  --margin-larger: 24px;

  --compact-section-margin : 16px;
}

a:link, a:visited {
  color: #00eedc !important;
  color: var(--accent-cool-darker) !important;
  text-decoration: none;
}
.App {
  /* width: 100vw;
  max-width: 100%;
  overflow-x: none; */

  position: relative;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "navigation content";
}

.content {
  position: relative;
  grid-area: content;
  width: 100%;
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 32px;

  grid-template-areas:
    "intro"
    "experience"
    "projects";
}

.navigation {
  grid-area: navigation;
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.intro-content {
  grid-area: intro;
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.experience-content {
  grid-area: experience;
}

.project-content {
  grid-area: projects;
}

.section-header {
  padding-bottom: 20px;
  font-size: calc(18px + (36 - 18) * ((100vw - 300px) / (1600 - 300)));
  color: var(--accent-cool-darker);
}

.contact-section{
  margin-bottom: 80px;
}
@media screen and (max-width: 900px) {
  .App {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "content";
  }

  .navigation {
    display: none;
    position: -webkit-sticky;
    position: sticky;
    height: 5%;
    margin-top: 0;
  }
}

.Contact {
  padding-bottom: 80px;
  padding: 10px;
  font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.Contact-tagline {
  /* font-size: 1.5em; */
}

@media screen and (max-width: 720px) {
  .Contact {
    padding-bottom: 80px;
    padding: 5px var(--compact-section-margin);
  }
}

.Job {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  min-width: 50%;
  overflow: hidden;
  justify-content: space-between;
}

.Job-data {

}

.Job-header {
  font-size: calc(18px + (36 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
}

.Job-date {
  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.Job-biline{
  font-size: calc(16px + (28 - 16) * ((100vw - 300px) / (1600 - 300)));

}
.Job-company{
}

.Job-location {

}

.responsibilities {
}

.responsibility {
  padding-bottom: 8px;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));

}

.open-modal {

  margin: 24px 0px 8px 0px;
  height: 32px;
  padding: 0;
  background: none;
  border: 0;
  font-size: 1em;
  font-weight: bold;
  color: var(--accent-cool-darker);
  font-size: calc(16px + (28 - 16) * ((100vw - 300px) / (1600 - 300)));
}


.Timeline {
  position: relative;
  margin: 0 auto;
}

.Timeline-header {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  color: var(--accent-cool-darker);
  padding-left: 70px;
}

.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid var(--tertiary-bg);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent var(--tertiary-bg);
}

.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid var(--tertiary-bg);
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--tertiary-bg) transparent transparent;
}

.Timeline-content {
  padding: 20px 30px;
  background-color: var(--tertiary-bg);
  position: relative;
  border-radius: 6px;
}

.Timeline::after {
  left: 31px;
  content: "";
  position: absolute;
  width: 6px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  top: 0;
  bottom: 0;
  margin-left: -3px;
  margin-top: 24px;
  height: 0%;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation: line 2s 1 linear;
          animation: line 2s 1 linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.Timeline-container {
  padding: 10px 40px;
  padding-left: 70px;
  padding-right: 25px;
  position: relative;
  background-color: inherit;
  padding-right: 5px;
}

.Timeline-container::before {
  left: 60px;
  border: medium solid var(--tertiary-bg);
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--tertiary-bg) transparent transparent;
}

.right {
  left: 0%;
}

@media screen and (max-width: 480px) {
  .Timeline-container {
    padding: 10px 20px;
    padding-left: 70px;
    padding-right: 5px;
    position: relative;
    background-color: inherit;
  }

  .Timeline-content {
    padding: 20px 20px;
  }
}

@-webkit-keyframes line {
  0% {
    height: 0%;
  }
  25% {
    height: 25%;
  }
  50% {
    height: 50%;
  }
  75% {
    height: 75%;
  }
  100% {
    height: 95%;
  }
}

@keyframes line {
  0% {
    height: 0%;
  }
  25% {
    height: 25%;
  }
  50% {
    height: 50%;
  }
  75% {
    height: 75%;
  }
  100% {
    height: 95%;
  }
}

.Intro {
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  color: var(--accent-cool);
  padding-left: 30px;
  margin-top: 15%;
  margin-bottom: 8px;

}

.intro-about-me {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:85%;
  max-width: 800px;
  padding-top: 10px;
}

.intro-name {
  font-size: calc(32px + (42 - 32) * ((100vw - 300px) / (1600 - 300)));
  padding-bottom: 30px;
}

.intro-tag-line {
  font-size: calc(32px + (42 - 32) * ((100vw - 300px) / (1600 - 300)));
}

.intro-see-more {
  margin-top: calc(50vh-50px);
  margin-top: 50vh;
}

.Card {
  display: flex;
  flex-direction: column;
  background-color: #282828;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  overflow: hidden;
  position: relative;
}

.Card-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

}

.Card-body > div:last-of-type {
  margin-top: auto;
}

.Card-media {
  min-height: 200px;
  height: 30%;
  max-height: 250px;
}

.Card-content{
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.Card-title {
  padding-top: 8px;
  font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
}

.Card-cta > a {
  text-decoration: none;
  text-transform: capitalize;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.Projects{
  margin : 10px;
}

.Projects-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

@media screen and (max-width: 480px) {
  .Projects-list {
    display: grid;
    grid-template-columns:1fr;
    grid-column-gap: 5px;
    grid-row-gap: var(--compact-section-margin);
  }  

  .Projects{
    margin : 5px var(--compact-section-margin);
  }

  .Projects-entry{
    justify-content: center;
    display: flex;
    
  }

  .Projects-entry {
    padding-bottom: 0;
  }
  
}


.Navigation {
  list-style: none;
  padding: 20px;
}

.Navigation > li {
  padding-bottom: 24px;
}

.Navigation > li > a {
  text-decoration: none;
  font-size: 2.5em;
}

.Navigation > li > a:active {
  color: var(--accent-cool-darker);
}

.Navigation > li > a:hover {
  text-decoration: underline;
}

.Navigation-active{
  color: var(--accent-cool-darker) !important;
}


.Doge {
  width: 100%;
  overflow: hidden;
  height: 400px;
  transform: translateY(-500px);
  padding-top: 25%;
  /* position: relative; */
}

.Doge-img-container {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  /* Starting position */
  transform: translateX(100%);
  /* Apply animation to this element */
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation: scroll-left 5s linear 1;
          animation: scroll-left 5s linear 1;
  
}

.Doge-img {
  height: 350px;
  transform: rotate(-45deg);
}

@-webkit-keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  25% {
    transform: translateX(45%);
  }
  50% {
    transform: translateX(45%);
  }
  75% {
    transform: translateX(45%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  25% {
    transform: translateX(45%);
  }
  50% {
    transform: translateX(45%);
  }
  75% {
    transform: translateX(45%);
  }
  100% {
    transform: translateX(100%);
  }
}

.AboutMe {
  max-width: 900px;
  padding: 10px;
  min-height: 300px;
}
.AboutMeContent {
  display: flex;
  flex-direction: row;

}

.AboutMeTextCont {
  color: var(--accent-cool-darker);
  width: 50%;
  margin-right: 42px;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  white-space: pre-line;
}

.AboutMeImgCont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

}

.AboutMeImg {
  border-radius: 50%;
  width: 80%;
  border-color: (--primary-bg);
  -webkit-clip-path: circle(49%);
          clip-path: circle(49%);

}

@media screen and (max-width: 720px) {
  .AboutMe {
    padding: 5px var(--compact-section-margin);
  }
  .AboutMeContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .AboutMeTextCont {
    color: var(--accent-cool-darker);
    width: 100%;
    margin-right: 0;
  }

  .AboutMeImgCont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .AboutMeImg {
    border-radius: 50%;
    width: 100%;
  }
}

