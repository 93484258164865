.Navigation {
  list-style: none;
  padding: 20px;
}

.Navigation > li {
  padding-bottom: 24px;
}

.Navigation > li > a {
  text-decoration: none;
  font-size: 2.5em;
}

.Navigation > li > a:active {
  color: var(--accent-cool-darker);
}

.Navigation > li > a:hover {
  text-decoration: underline;
}

.Navigation-active{
  color: var(--accent-cool-darker) !important;
}
