.App {
  /* width: 100vw;
  max-width: 100%;
  overflow-x: none; */

  position: relative;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "navigation content";
}

.content {
  position: relative;
  grid-area: content;
  width: 100%;
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 32px;

  grid-template-areas:
    "intro"
    "experience"
    "projects";
}

.navigation {
  grid-area: navigation;
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.intro-content {
  grid-area: intro;
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.experience-content {
  grid-area: experience;
}

.project-content {
  grid-area: projects;
}

.section-header {
  padding-bottom: 20px;
  font-size: calc(18px + (36 - 18) * ((100vw - 300px) / (1600 - 300)));
  color: var(--accent-cool-darker);
}

.contact-section{
  margin-bottom: 80px;
}
@media screen and (max-width: 900px) {
  .App {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "content";
  }

  .navigation {
    display: none;
    position: sticky;
    height: 5%;
    margin-top: 0;
  }
}
