.Intro {
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  color: var(--accent-cool);
  padding-left: 30px;
  margin-top: 15%;
  margin-bottom: 8px;

}

.intro-about-me {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:85%;
  max-width: 800px;
  padding-top: 10px;
}

.intro-name {
  font-size: calc(32px + (42 - 32) * ((100vw - 300px) / (1600 - 300)));
  padding-bottom: 30px;
}

.intro-tag-line {
  font-size: calc(32px + (42 - 32) * ((100vw - 300px) / (1600 - 300)));
}

.intro-see-more {
  margin-top: calc(50vh-50px);
  margin-top: 50vh;
}
