
.Doge {
  width: 100%;
  overflow: hidden;
  height: 400px;
  transform: translateY(-500px);
  padding-top: 25%;
  /* position: relative; */
}

.Doge-img-container {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  /* Starting position */
  transform: translateX(100%);
  /* Apply animation to this element */
  animation-delay: 1s;
  animation: scroll-left 5s linear 1;
  
}

.Doge-img {
  height: 350px;
  transform: rotate(-45deg);
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  25% {
    transform: translateX(45%);
  }
  50% {
    transform: translateX(45%);
  }
  75% {
    transform: translateX(45%);
  }
  100% {
    transform: translateX(100%);
  }
}
