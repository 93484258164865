.Card {
  display: flex;
  flex-direction: column;
  background-color: #282828;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  overflow: hidden;
  position: relative;
}

.Card-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

}

.Card-body > div:last-of-type {
  margin-top: auto;
}

.Card-media {
  min-height: 200px;
  height: 30%;
  max-height: 250px;
}

.Card-content{
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.Card-title {
  padding-top: 8px;
  font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
}

.Card-cta > a {
  text-decoration: none;
  text-transform: capitalize;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
}
