.Contact {
  padding-bottom: 80px;
  padding: 10px;
  font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
}

.Contact-tagline {
  /* font-size: 1.5em; */
}

@media screen and (max-width: 720px) {
  .Contact {
    padding-bottom: 80px;
    padding: 5px var(--compact-section-margin);
  }
}
