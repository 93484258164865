.Job {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  min-width: 50%;
  overflow: hidden;
  justify-content: space-between;
}

.Job-data {

}

.Job-header {
  font-size: calc(18px + (36 - 18) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
}

.Job-date {
  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.Job-biline{
  font-size: calc(16px + (28 - 16) * ((100vw - 300px) / (1600 - 300)));

}
.Job-company{
}

.Job-location {

}

.responsibilities {
}

.responsibility {
  padding-bottom: 8px;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));

}

.open-modal {

  margin: 24px 0px 8px 0px;
  height: 32px;
  padding: 0;
  background: none;
  border: 0;
  font-size: 1em;
  font-weight: bold;
  color: var(--accent-cool-darker);
  font-size: calc(16px + (28 - 16) * ((100vw - 300px) / (1600 - 300)));
}

